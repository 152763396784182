/*
	Overlays
*/

.overlay-controller {
	z-index: 2;

	background: rgba($tour-light-background-color, .95);

	color: $union-text-color;

	.view-close-button {
		background-image: url("#{$img-root}/ui/system-icon-close-light.svg");

	}

	body.IE & {
		overflow: auto;

		padding-left: 30px;
		padding-right: 30px;

	}

	> div {
		position: absolute;

		body.IE & {
			position: static;

		}

		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		overflow: auto;

		> .text-container {
			min-height: 100%;

			> div {
				flex: 0 1 auto;

				background: #ffffff;
				box-shadow: 0 4px 8px rgba(0, 0, 0, .25);

				margin-left: auto;
				margin-right: auto;

				padding: 65px 75px;

				@media (max-width: $screen-m) {
					padding: 50px;

				}

				@media (max-width: $screen-s) {
					padding: 35px;

				}

			}

		}

	}

}

.overlay {
	body.IE & {
		display: table;

	}

	h2, h3.h1, h4, .lead {
		color: $union-blue;

	}

	h1:first-child {
		margin-top: -.2em;

	}

	.lead + .h1 {
		margin-top: 5px;

	}

	.h1 + .lead {
		margin-top: 45px;

	}

	h3 .subline {
		display: block;
		margin-top: 4px;

		@extend %copy-text-large;

	}

	.dark {
		color: $union-text-color;

	}

	.meta-headline {
		color: $union-blue;

		margin-bottom: 10px;

	}

	.timetable {
		margin-top: 45px;

		td {
			vertical-align: top;

			&:first-child {
				width: 120px;

			}

		}

		tr:not(:first-child) {
			td {
				padding-top: 35px;

			}

		}

		h4 .green {
			display: block;
			color: $union-green;
			font-weight: 300;

		}

		&--green-times {
			td:first-child {
				color: $union-green;

			}

		}

	}

	ul.download-list {
		list-style: none;

		li {
			margin-top: 17px;

			text-indent: -36px;
			padding-left: 36px;

			@media (max-width: $screen-m) {
				text-indent: -35px;
				padding-left: 35px;

			}

		}

		li + li {
			margin-top: 15px;

		}

		a {
			text-decoration: none;
			color: $union-blue;

			> span {
				text-decoration: underline;

			}

			&:hover {
				color: $union-green;

			}

			&:before {
				content: " ";
				display: inline-block;

				@extend .label-icon;
				@extend .label-icon--download;

				top: -1px !important;

				margin-right: 6px !important;

				background-image: url("#{$img-root}/ui/system-icon-document-pdf-pos.svg") !important;

			}

			&:hover:before {
				background-image: url("#{$img-root}/ui/system-icon-document-pdf-pos-green.svg") !important;

			}

		}

	}

	&.downloads-overlay {
		.text-container {
			max-width: 600px;

		}

		h2, .lead.strong, .action-button {
			margin-top: 38px;

		}

		h2, .lead.strong {

		}

		.label-icon {
			margin-right: 9px;

		}

	}

	&.help-overlay {
		.text-container {
			max-width: none;

		}

		h2 {
			&.small {
				font-size: 28px;
				line-height: 36px;

				@media (max-width: $screen-m) {
					font-size: 26px;
					line-height: 34px;

				}

			}

			a {
				color: inherit;
				text-decoration: none;

				white-space: nowrap;

				&:hover {
					text-decoration: underline;

				}

				@extend %prepended-headline-icon;

				&.phone-link:before {
					background-image: url("#{$img-root}/ui/system-icon-phone-green-bold.svg");

				}

				&.mail-link:before {
					top: 2px;

					background-image: url("#{$img-root}/ui/system-icon-mail-green-bold.svg");

				}

			}

		}

	}

	.text-container.i-frame-container {
		position: absolute;

		max-width: none;
		width: 100%;

		display: flex;
		flex-direction: column;

		padding: 0;

		body.IE & {
			display: table;
			height: calc(100% - 120px);

		}

		> div {
			position: relative;

			flex: 1 0 auto;

			padding: 0;

			margin: 60px auto;

			width: calc(100% - 160px);
			height: 100%;

			max-width: 800px;

			@media (max-width: $screen-m) {
				width: calc(100% - 60px);

			}

			iframe {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;

				border: none;

			}

		}

	}

	&.privacy-overlay {
		h2 {
			@extend %copy-text-large;

			&.strong {
				@extend %headline;

			}

		}

	}

}
