/*
	Log-in overlay
*/

.overlay-controller.log-in {
	position: absolute;
	
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	
	// background: rgba(#ffffff, .925);
	// background: url("#{$img-root}/ui/markenform-frame.svg") 75% 12.5%/cover no-repeat;
	background: transparent;
	
	form {
		h2 {
			@extend %copy-text;
			font-weight: bold;
			
			margin-top: 1.75rem;
			
			&:not(:first-child) {
				margin-top: 1.25rem;
				
			}
			
			&.error {
				color: $union-signal-red;
				
			}
			
		}
		
		input {
			font-family: inherit;
			
		}
		
		input[type=text], input[type=email], input[type=password] {
			display: block;
			
			@extend %bordered-control-padding;
			border: 1px solid $union-blue;
			
			@extend %copy-text;
			
			border-radius: 0.25rem;
			
			margin-top: 13px;
			width: 100%;
			
			&:focus {
				outline: none;
				border-color: $union-green;
				
			}
			
			&.error {
				border-color: $union-signal-red;
				
			}
			
		}
		
		input[type=checkbox] {
			appearance: none;
			
			position: absolute;
			
			width: 30px;
			height: 30px;
			
			border: 1px solid $union-blue;
			
			border-radius: 0.25rem;
			
			transform: translateY(6px);
			
			&:checked {
				background: url("#{$img-root}/ui/system-icon-checkmark.svg") 50%/20px no-repeat;
				
			}
			
			&:active {
				background: transparent;
				border-color: $union-green;
				
			}
			
			+ label {
				display: block;
				margin-top: 3px;
				margin-bottom: 3px;
				margin-left: 50px;
				
				@extend %copy-text;
				color: $union-blue;
				
			}
			
			&, + label {
				cursor: pointer;
				
			}
			
		}
		
		[type=submit] {
			display: block;
			margin-left: auto;
			
			min-width: 130px;
			
			@extend %action-button;
			border: none;
			
			margin-top: 2.5rem;
			
			cursor: pointer;
			
		}
		
		.error-message {
			margin-top: 2.5rem;
			margin-bottom: .75rem;
			
			max-width: 360px;

			color: $union-blue;

			strong {
				font-weight: 600;
				
				color: $union-signal-red;
				
				display: block;
				margin-bottom: 9px;
				
			}
			
			a {
				color: $union-green;
				font-weight: 600;
				text-decoration: none;
				
			}
			
		}
		
	}
	
	&.pre-launch {
		// background: rgba($union-blue, .925);
		background: rgba(#ffffff, .925);
		
		body.IE & {
			> div {
				max-width: 680px;
				
				margin-left: auto;
				margin-right: auto;
				
			}
			
		}
		
		.text-container {
			max-width: 660px;
			
		}
		
		.markenform {
			background: transparent;
			box-shadow: none;
			
			&:before {
				 background: rgba(#ffffff, .275);
				// background: $union-blue;
				// background: rgba($union-blue, .275);
				
			}
			
			/*
			* {
				color: #ffffff;
				
			}
			*/
			
			padding-top: calc(25% / 2);
			padding-bottom: calc(21.5% / 2);
			
		}
		
	}
	
}
