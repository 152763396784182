/*
	Video player
*/

.tour-video-player {
	position: absolute;
	z-index: 1;

	/*
	left: 50.6% !important;
	top: 46.7% !important;

	width: 43.2%;
	height: 43.6%;
	*/

	/*
	width: 100%;
	height: 100%;

	.sprite {
		width: 100%;
		height: 100%;

	}
	*/

	.video-player-zoom-button {
		position: absolute;
		z-index: 1;

		left: calc(-50% + 13px);
		top: calc(-50% + 13px);

		width: 24px;
		height: 24px;

		background: 50%/contain no-repeat;

		background-image: url("#{$img-root}/ui/system-icon-expand-neg.svg");

		opacity: .85;

		&:hover {
			background-image: url("#{$img-root}/ui/system-icon-expand-neg-green.svg");

			opacity: 1;

		}

	}

	&.flipped {
		.video-player-zoom-button {
			left: calc(50% - 0px);

			background-image: url("#{$img-root}/ui/system-icon-expand-flipped-neg.svg");

			&:hover {
				background-image: url("#{$img-root}/ui/system-icon-expand-flipped-neg-green.svg");

			}

		}

	}

	.video-player-language-button {
		pointer-events: initial;

		position: absolute;
		z-index: 2;

		left: calc(-50% + 65px + 13px);
		top: calc(-50% + 2px);

		font-size: 13px;
		line-height: 20px;

		cursor: pointer;

		padding-top: 10px;
		padding-bottom: 10px;

		color: #c0c0c0;

		&:hover {
			color: $union-green;

		}

		 display: none !important;

	}

	&.fullscreen,
	&:hover {
		.video-player-language-button {
			display: block;

		}

	}

	/*
	&:hover {
		.streaming-player-container {
			&:before {
				content: "";
				display: block;

				position: absolute;
				z-index: 1;

				left: -50%;
				top: -50%;

				width: 100%;
				height: 48px;

				background: rgba(0, 0, 0, .8);

			}

		}

	}
	*/

	&.zoomed {
		.streaming-player-container {
			&:before {
				content: none;

			}

		}

	}

	video.sprite {
		transform: translate(-50%, -50%);

	}

	.streaming-player-container {
		&:after {
			content: " ";
			display: block;

			position: absolute;
			z-index: -1;

			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

			background: #282828; // $union-light-grey;
			transform: translate(-50%, -50%);

		}

		pointer-events: none;
		-webkit-touch-callout: none;

		.video-player-zoom-button,
		.js3q-wrapper,
		iframe {
			pointer-events: initial;
			-webkit-touch-callout: initial;

		}

		::cue {
			@extend %copy-text;

		}

		video::-webkit-media-text-track-container {
			body.SAFARI & {
				position: relative;

			}

		}

		video::-webkit-media-text-track-display {
			body.SAFARI & {
				position: absolute;

				top: auto !important;
				bottom: calc(100% - 18px);

				width: 100%;

				font-family: "FrutigerNeue", sans-serif;

				font-size: 16px !important;
				line-height: 20px !important;

				@media (max-width: $screen-m) {
					bottom: calc(100% - 14px);

					font-size: 14px !important;
					line-height: 18px !important;

				}

			}

		}

		video::cue {
			body.SAFARI & {
				background: rgba($union-blue, .8);

			}

		}

	}

	.js3q-wrapper {
		transform: translate(-50%, -50%);

	}

	.video-player-i-frame {
		border: none;

		position: absolute;

		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		
		left: 0;
		top: 0;
		width: calc(100%);
		height: calc(100%);


		transform: translate(-50%, -50%);

		overflow: hidden;
		@extend %scroll-bar-disabled;

	}

}

.video-player-i-frame:fullscreen  {
	transform: none;

}

/*
.streaming-player .view-close-button,
[id=auditorium] .view-close-button {
	left: auto !important;
	right: 13px;

}
*/

.tour-video-player {
	video.sprite:fullscreen {
		transform: none !important;

	}

}

.view-close-button.flipped {
	left: auto !important;
	right: 13px;

}
