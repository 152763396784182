/*
	Tour stage
*/

.tour-stage {
	position: absolute;
	width: 100%;
	height: 100vh;
	
	overflow: hidden;
	
	background: $tour-background-color;
	color: #ffffff;
	
}

.section-back-button {
	position: absolute;
	
	top: 13px;
	left: 30px;
	
	.action-button {
		@extend %action-button__transparent;
		
	}
	
}

.view-close-button {
	display: block;
	
	position: absolute;
	z-index: 1;
	
	top: 13px;
	left: 30px;
	
	width: 20px;
	height: 20px;
	
	background: 50%/contain no-repeat;
	background-image: url("#{$img-root}/ui/system-icon-close-dark.svg");
	
}

.tour-message-button {
	display: block;
	
	position: absolute;
	z-index: 1;
	
	bottom: 13px;
	left: 30px;
	
	//@extend %action-button__transparent;
	
	background: rgba($union-blue, .4);
	
	&:hover {
		background: $union-green;
		
	}
	
	// .tour-section-content.streaming-player & {
		bottom: 55px;
		
		background: rgba($union-blue, .8);
		
	// }
	
}

/*
	Mouse spot
*/

.mouse-spot {
	@extend .shape;
	
	 display: none;
	
	position: absolute;
	
	z-index: 32;
	
	&:after {
		content: " ";
		
		position: absolute;
		
		width: 30px;
		height: 30px;
		
		border-radius: 50%;
		
		transform: translate(-50%, -50%);
		
		background: rgba(0, 128, 255, .5);
		
	}
	
}
