body {
	background: $tour-background-color;
	
	&, * {
		box-sizing: border-box;
		
	}
	
}

body.IE {
	
}
