/*
	Tour split view
*/

$split-view-background-colour: #282828;

.tour-split-view {
	position: absolute;
	
	background: $split-view-background-colour;
	
	width: 200px;
	height: 300px;
	
	border-top: 4px solid $tour-background-color;
	
	display: flex;
	flex-direction: column;
	
	justify-content: center;
	align-items: center;
	
	.tour-split-view-icon {
		width: 120px;
		height: 115px;
		
		background: url("#{$img-root}/ui/content-icon-waving-hand.svg") 50%/contain no-repeat;
		
		margin-left: -7px;
		margin-top: -3px;
		
	}
	
	.action-button {
		@media (max-width: $screen-l) {
			display: block;
			
		}
		
		&--restart {
			border-color: $union-green !important;
			
		}
		
	}
	
	> div {
		// background: rgba(green, .2);
		
		width: calc(100% - 200px);
		max-width: 840px;

		@media (max-width: $screen-m) {
			width: calc(100% - 160px);
			
		}
		
		flex: 1 0 auto;
		
		display: flex;
		flex-direction: column;
		
		padding-top: 7px;
		padding-bottom: 13px;
		
		> * {
			&:first-child {
				flex: 0 1 auto;
				
			}
			
			&:last-child {
				margin-top: 13px;
				
				flex: 1 0 auto;
				
				display: flex;
				
				align-items: stretch;
				
				> * {
					&:first-child {
						flex: 1 0 auto;
						
						margin-right: 20px;
						
						@media (min-width: $screen-m-up) {
							min-width: 480px;
							
						}
						
					}
					
					&:last-child {
						flex: 0 1 auto;
						
						align-self: flex-start;
						
					}
					
				}
				
				.action-button.disabled {
					@extend .shape;
					opacity: .2;
					
				}
				
				&.sent {
					> * {
						&:last-child {
							@media (min-width: $screen-m-up) {
								align-self: flex-end;
								
							}
							
							.action-button {
								margin-top: 20px;
								
							}
							
						}
						
					}
					
					textarea {
						border-color: #707070;
						
					}
					
					@media (max-width: $screen-m) {
						.optional {
							display: none;
							
						}
						
					}
					
					.optional {
						.chat-disabled &,
						body.IE & {
							display: none;
							
						}
						
					}
					
				}
				
				@media (max-width: $screen-m) {
					flex-direction: column;
					
					> * {
						&:first-child {
							margin-right: 0;
							margin-bottom: 13px;
							
						}
						
					}
					
				}
				
			}
			
		}
		
	}
	
	h2, p {
		color: #989898;
		
		margin-left: 15px;
		
		@media (max-width: $screen-l) {
			margin-left: 8px;
			
		}
		
	}
	
	p {
		@media (min-width: $screen-l-up) {
			margin-left: 0;
			
		}
		
		a {
			color: inherit;
			
		}
		
	}
	
	h2 {
		// @extend %prepended-headline-icon;
		
		@extend %copy-text-large;
		// font-weight: bold;
		
		&:before {
			background-image: url("#{$img-root}/ui/content-icon-waving-hand.svg");
			
			margin-left: -3px;
			margin-right: 6px;
			
			margin-bottom: -12px;
			
		}
		
	}
	
	.success-message {
		@extend %small-print;
		
		margin-bottom: -4px;
		
		em {
			font-style: normal;
			color: $union-green;
			
		}
		
		&.error-message {
			margin-top: 15px;
			
			color: $union-signal-red;
			
		}
		
	}
	
	form {
		textarea {
			display: block;
			
			resize: none;
			
			background: $split-view-background-colour;
			
			border: 1px solid $union-grey;
			border-radius: 0.25rem;
			
			@extend %bordered-control-padding;
			
			@extend %copy-text;
			font-family: inherit;
			
			color: #ffffff;
			
			&::placeholder {
				color: #707070;
				
			}
			
			&:focus {
				outline: none;
				
				border-color: $union-green;
				
			}
			
		}
		
		.action-button {
			@extend %bordered-control-padding;
			
			background: transparent;
			border: 1px solid $union-grey;
			
			.label-icon {
				top: -4px;
				margin-right: 5px;
				
			}
			
			&:hover {
				background: $union-green;
				border-color: $union-green;
				
				/*
				border-color: $union-green;
				color: $union-green;
				
				.label-icon {
					background-image: url("#{$img-root}/ui/system-icon-send-neg-green.svg");
					
				}
				*/
				
			}
			
		}
		
	}
	
	.view-close-button {
		background-image: url("#{$img-root}/ui/system-icon-close-dark.svg");
		
	}
	
	&.credit-split-view, &.expert-split-view {
		background: $tour-light-background-color;
		
		h2 {
			color: $union-blue;
			font-weight: bold;
			
		}
		
		form {
			textarea {
				background: transparent;
				color: $union-text-color;
				
			}
			
			.action-button {
				background: $union-blue;
				border-color: $union-blue;
				
				&:hover {
					background: $union-green;
					border-color: $union-green;
					
				}
				
				&.disabled {
					opacity: .55;
					
				}
				
			}
			
		}

		.view-close-button {
			background-image: url("#{$img-root}/ui/system-icon-close-light.svg");

		}

	}
	
}

