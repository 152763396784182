/*
	Debug
*/
#debugOut {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 819200;
  border-style: none;
  margin: 0;
  padding: 0;
  font-family: bold 11px/20px sans-serif;
}
#debugOut pre {
  margin: 2px 4px 0px 4px;
  background-color: rgba(255, 255, 255, 0.75);
  color: #303030;
  text-shadow: 0px 1px 2px #ffffff;
}
#debugOut.negative pre {
  background-color: rgba(0, 0, 0, 0.25);
  color: #e8e8e8;
  text-shadow: 0px 1px 2px #000;
}

/*
	Display
*/
.sprite {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}
.sprite--button {
  cursor: pointer;
}

img.sprite, .fast-sprite {
  -webkit-perspective: 10px;
  -webkit-perspective-origin: 50% 50%;
}

.shape, .tour-split-view > div > *:last-child .action-button.disabled, .mouse-spot, .header-navigation .header-title span, .horizontal-ruler,
.vertical-ruler,
.grid-ruler {
  pointer-events: none;
  -webkit-touch-callout: none;
}

.shape, .tour-split-view > div > *:last-child .action-button.disabled, .mouse-spot, .header-navigation .header-title span, .horizontal-ruler,
.vertical-ruler,
.grid-ruler, .unselectable, .header-navigation, .button {
  user-select: none;
}

object, embed {
  width: 100%;
  height: 100%;
}
object:focus, embed:focus {
  outline: none;
}

.horizontal-ruler,
.vertical-ruler,
.grid-ruler {
  position: fixed;
  left: 0;
  top: 0;
}

.horizontal-ruler {
  width: 100%;
  height: 1px;
}

.vertical-ruler {
  width: 1px;
  height: 100%;
}

.grid-ruler {
  width: 100%;
  /*
  z-index: 16000;

  .col-1 {
  	background: rgba(255, 0, 0, .5);

  	> div {
  		height: 10px;
  		background: rgba(0, 255, 0, .5);

  	}

  }
  */
}

/*
	UI
*/
body.point * {
  cursor: pointer;
}
body.grab * {
  cursor: grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: -webkit-grabbing !important;
}
body.scroll-locked {
  overflow: hidden;
  touch-action: none;
}

/*
	Disable scroll bars
*/
.tour-video-player .video-player-i-frame, html, body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.tour-video-player .video-player-i-frame::-webkit-scrollbar, html::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none;
}

/*
	Image root
*/
/*
	Breakpoints
*/
/*
	Colours
*/
/*
	Web fonts
*/
@font-face {
  font-family: "FrutigerNeue";
  src: url("../fonts/unlicensed/FrutigerNeueLTW06-Book.woff2") format("woff2"), url("../fonts/unlicensed/FrutigerNeueLTW06-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "FrutigerNeue";
  src: url("../fonts/unlicensed/FrutigerNeueLTW06-Bold.woff2") format("woff2"), url("../fonts/unlicensed/FrutigerNeueLTW06-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "UIIconFont";
  src: url("../fonts/UI-IconFont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.overlay.privacy-overlay h2.strong, h2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
}
@media (max-width: 1023px) {
  .overlay.privacy-overlay h2.strong, h2 {
    font-size: 28px;
    line-height: 36px;
  }
}

h3 {
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
}
@media (max-width: 1023px) {
  h3 {
    font-size: 18px;
    line-height: 26px;
  }
}

h1, .h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
}
@media (max-width: 1023px) {
  h1, .h1 {
    font-size: 32px;
    line-height: 44px;
  }
}

.overlay-controller.log-in form input[type=checkbox] + label, .overlay-controller.log-in form input[type=text], .overlay-controller.log-in form input[type=email], .overlay-controller.log-in form input[type=password], .overlay-controller.log-in form h2, .tour-video-player .streaming-player-container ::cue, .tour-split-view form textarea, .chat-controller h2, body {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
@media (max-width: 1023px) {
  .overlay-controller.log-in form input[type=checkbox] + label, .overlay-controller.log-in form input[type=text], .overlay-controller.log-in form input[type=email], .overlay-controller.log-in form input[type=password], .overlay-controller.log-in form h2, .tour-video-player .streaming-player-container ::cue, .tour-split-view form textarea, .chat-controller h2, body {
    font-size: 14px;
    line-height: 22px;
  }
}

.overlay.privacy-overlay h2, .overlay h3 .subline, .tour-split-view h2, .header-navigation .header-title span, p.lead {
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
}
@media (max-width: 1023px) {
  .overlay.privacy-overlay h2, .overlay h3 .subline, .tour-split-view h2, .header-navigation .header-title span, p.lead {
    font-size: 22px;
    line-height: 30px;
  }
}

.tour-split-view .success-message {
  font-size: 13px;
  line-height: 20px;
}

body {
  font-family: "FrutigerNeue", sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #404040;
}
body, body * {
  margin: 0;
  padding: 0;
}

h1 span, .h1 span {
  color: #43b02a;
}

* + h2 {
  margin-top: 1.5rem;
}

* + h3 {
  margin-top: 1.5rem;
}

h1, h2, h3 {
  color: #00358e;
}

p:not([class]) {
  margin-top: 16px;
}
@media (max-width: 767px) {
  p:not([class]) {
    margin-top: 14px;
  }
}

* + p.lead {
  margin-top: 0.55em;
}

p a {
  color: #00358e;
}
p a:hover {
  color: #43b02a;
}

strong {
  font-weight: bold;
  color: #43b02a;
}

/*
	Interaction
*/
.overlay-controller.log-in form [type=submit], .tour-section .tour-hot-spot--label, .action-button {
  padding: 14px 16px;
}
@media (max-width: 1279px) {
  .overlay-controller.log-in form [type=submit], .tour-section .tour-hot-spot--label, .action-button {
    padding: 9px 10px 10px;
  }
}

.overlay-controller.log-in form input[type=text], .overlay-controller.log-in form input[type=email], .overlay-controller.log-in form input[type=password], .tour-split-view form .action-button, .tour-split-view form textarea {
  padding: 13px 15px;
}
@media (max-width: 1279px) {
  .overlay-controller.log-in form input[type=text], .overlay-controller.log-in form input[type=email], .overlay-controller.log-in form input[type=password], .tour-split-view form .action-button, .tour-split-view form textarea {
    padding: 8px 9px 9px;
  }
}

.overlay-controller.log-in form [type=submit], .tour-section .tour-hot-spot--label, .action-button {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.27rem;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 0.25rem;
  color: #ffffff;
  background-color: #00358e;
}
.overlay-controller.log-in form [type=submit]:hover, .tour-section .tour-hot-spot--label:hover, .action-button:hover {
  background: #43b02a;
}
@media (max-width: 1279px) {
  .overlay-controller.log-in form [type=submit], .tour-section .tour-hot-spot--label, .action-button {
    font-size: 13px;
    line-height: 20px;
  }
  .overlay-controller.log-in form [type=submit] .label-icon, .overlay-controller.log-in form [type=submit] .overlay ul.download-list a:before, .overlay ul.download-list .overlay-controller.log-in form [type=submit] a:before, .overlay-controller.log-in form .overlay ul.download-list [type=submit] a:before, .tour-section .tour-hot-spot--label .label-icon, .tour-section .tour-hot-spot--label .overlay ul.download-list a:before, .overlay ul.download-list .tour-section .tour-hot-spot--label a:before, .action-button .label-icon, .action-button .overlay ul.download-list a:before, .overlay ul.download-list .action-button a:before {
    margin-bottom: -11px;
  }
}

.tour-section .tour-hot-spot--label, .section-back-button .action-button {
  background: rgba(0, 53, 142, 0.8);
}
.tour-section .tour-hot-spot--label:hover, .section-back-button .action-button:hover {
  background: #43b02a;
}

.action-button {
  border: none;
}
* + .action-button {
  margin-top: 1.85em;
}

/*
	Grid
*/
.text-container {
  width: calc(100% - 60px);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2.5rem;
  padding-bottom: calc(2.5rem + 0.5vh);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.text-container > * {
  flex: 0 1 auto;
}
body.IE .text-container {
  display: table-cell;
  min-height: 0 !important;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
}
body.IE .text-container > * {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.markenform {
  position: relative;
  padding-left: 45px;
  padding-right: 45px;
  padding-top: 12.5%;
  padding-bottom: 7.5%;
}
.markenform:before {
  content: " ";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: skewY(-11deg);
  border: 2px solid #43b02a;
  border-radius: 0 84px;
}
@media (max-width: 767px) {
  .markenform:before {
    border-radius: 0 72px;
  }
}

/*
	Icons
*/
.label-icon, .overlay ul.download-list a:before {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 4px;
  background: 50%/contain no-repeat;
  margin-bottom: -9px;
  position: relative;
  top: -3px;
}
.label-icon--person {
  background-image: url("../data/images/ui/system-icon-person-neg.svg");
}
.label-icon--form {
  background-image: url("../data/images/ui/system-icon-form-neg.svg");
}
.label-icon--form-checked {
  background-image: url("../data/images/ui/system-icon-form-checked-neg.svg");
}
.label-icon--chat {
  top: -2px;
  background-image: url("../data/images/ui/system-icon-chat-neg-light.svg");
}
.label-icon--play {
  background-image: url("../data/images/ui/system-icon-play-neg.svg");
}
.label-icon--phone {
  margin-right: 3px;
  background-image: url("../data/images/ui/system-icon-phone-neg.svg");
}
.label-icon--mail {
  background-image: url("../data/images/ui/system-icon-mail-neg.svg");
  width: 24px;
}
.label-icon--calendar {
  background-image: url("../data/images/ui/system-icon-calendar-neg-light.svg");
  width: 24px;
}
.label-icon--waving-hand {
  background-image: url("../data/images/ui/system-icon-waving-hand-neg.svg");
}
.label-icon--home {
  background-image: url("../data/images/ui/system-icon-home-neg.svg");
}
.label-icon--back {
  top: -4px;
  margin-left: -1px;
  margin-right: 5px;
  background-image: url("../data/images/ui/system-icon-back-neg-light.svg");
}
.label-icon--download, .overlay ul.download-list a:before {
  background-image: url("../data/images/ui/system-icon-files-neg-light.svg");
}
.label-icon--web-page {
  background-image: url("../data/images/ui/system-icon-web-page-neg.svg");
}
.label-icon--send {
  background-image: url("../data/images/ui/system-icon-send-neg.svg");
}
.label-icon--reload {
  background-image: url("../data/images/ui/system-icon-reload-neg.svg");
}
.label-icon--log-in {
  background-image: url("../data/images/ui/system-icon-log-in-neg.svg");
}
.label-icon--credits {
  background-image: url("../data/images/ui/system-icon-credit-points-neg.svg");
}

.overlay.help-overlay h2 a:before {
  content: " ";
  display: inline-block;
  position: relative;
  left: -1px;
  width: 40px;
  height: 40px;
  margin-right: 11px;
  margin-bottom: -8px;
  background: 50%/contain no-repeat;
  top: 1px;
}
@media (max-width: 1023px) {
  .overlay.help-overlay h2 a:before {
    width: 36px;
    height: 36px;
    margin-right: 10px;
    top: 1px;
  }
}

.header-navigation .tools-button .notification {
  position: absolute;
  top: -5px;
  right: -8px;
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #e42444 url("../data/images/ui/chat-notification-asterisk.svg") 50%/10px no-repeat;
}

body {
  background: #111111;
}
body, body * {
  box-sizing: border-box;
}

/*
	Header navigation
*/
.header-navigation {
  position: relative;
  z-index: 1024;
  left: 0;
  top: 0;
  width: 100%;
  height: 64px;
  padding: 13px 30px;
  background: #00358e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
}
.header-navigation .action-button {
  border: 1px solid #43b02a;
  padding: 8px 10px;
  font-size: 13px;
  line-height: 20px;
}
.header-navigation .action-button:hover {
  background: #43b02a;
}
.header-navigation > * {
  flex: 0 1 auto;
}
.header-navigation .header-title {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.header-navigation .header-title > * {
  flex: 0 1 auto;
}
.header-navigation .header-title .header-logo {
  height: 28px;
  margin-right: 29px;
}
.header-navigation .header-title span {
  position: relative;
  top: 1px;
  white-space: nowrap;
}
@media (max-width: 1023px) {
  .header-navigation .header-title span {
    top: 2px;
  }
}
.header-navigation .header-title span strong {
  font-weight: bold;
  color: #43b02a;
}
.header-navigation .header-title .tools-button {
  display: none;
  margin-left: 0;
  margin-right: 29px;
}
.header-navigation .tools-navigation {
  display: flex;
  align-items: center;
}
.header-navigation .tools-navigation > * {
  flex: 0 1 auto;
}
.header-navigation .tools-navigation > * + * {
  margin-left: 30px;
}
.header-navigation .tools-button {
  font-size: 0;
  line-height: 0;
  width: 25px;
  height: 25px;
  background: 50%/contain no-repeat;
  position: relative;
}
.header-navigation .tools-button--auditorium {
  top: 0px;
  margin-left: 22px;
  margin-right: -8px;
  background-image: url("../data/images/ui/system-icon-play-green.svg");
}
.header-navigation .tools-button--auditorium:hover, .header-navigation .tools-button--auditorium.active {
  background-image: url("../data/images/ui/system-icon-play-green-neg.svg");
}
.header-navigation .tools-button--chat {
  top: 2px;
  background-image: url("../data/images/ui/system-icon-chat-green-neg.svg");
}
.header-navigation .tools-button--chat:hover, .header-navigation .tools-button--chat.active {
  background-image: url("../data/images/ui/system-icon-chat-neg.svg");
}
.header-navigation .tools-button--poll {
  top: 0px;
  margin-left: 27px;
  background-image: url("../data/images/ui/system-icon-poll-green-neg.svg");
}
.header-navigation .tools-button--poll:hover, .header-navigation .tools-button--poll.active {
  background-image: url("../data/images/ui/system-icon-poll-neg.svg");
}
.header-navigation .tools-button--credit-points {
  top: 0px;
  background-image: url("../data/images/ui/system-icon-credit-points-green-neg.svg");
  margin-left: 28px;
}
.header-navigation .tools-button--credit-points:hover, .header-navigation .tools-button--credit-points.active {
  background-image: url("../data/images/ui/system-icon-credit-points-green-neg-light.svg");
}
.header-navigation .tools-button--document {
  top: 1px;
  margin-left: 25px;
  background-image: url("../data/images/ui/system-icon-document-green-neg.svg");
}
.header-navigation .tools-button--document:hover, .header-navigation .tools-button--document.active {
  background-image: url("../data/images/ui/system-icon-document-neg.svg");
}
.header-navigation .tools-button--files {
  top: 1px;
  margin-left: 25px;
  margin-right: 2px;
  background-image: url("../data/images/ui/system-icon-files-green-neg.svg");
}
.header-navigation .tools-button--files:hover, .header-navigation .tools-button--files.active {
  background-image: url("../data/images/ui/system-icon-files-neg.svg");
}
.header-navigation .tools-button--calendar {
  top: 1px;
  margin-left: 26px;
  background-image: url("../data/images/ui/system-icon-calendar-green-neg.svg");
}
.header-navigation .tools-button--calendar:hover, .header-navigation .tools-button--calendar.active {
  background-image: url("../data/images/ui/system-icon-calendar-neg.svg");
}
.header-navigation .tools-button--help {
  top: 1px;
  background-image: url("../data/images/ui/system-icon-help-green-neg.svg");
}
.header-navigation .tools-button--help:hover, .header-navigation .tools-button--help.active {
  background-image: url("../data/images/ui/system-icon-help-neg.svg");
}
.header-navigation .tools-button--privacy {
  top: 1px;
  margin-left: 22px;
  background-image: url("../data/images/ui/system-icon-privacy-green-neg.svg");
}
.header-navigation .tools-button--privacy:hover, .header-navigation .tools-button--privacy.active {
  background-image: url("../data/images/ui/system-icon-privacy-neg.svg");
}
.header-navigation .tools-button--back {
  top: 1px;
  background-image: url("../data/images/ui/system-icon-back-green-neg.svg");
}
.header-navigation .tools-button--back:hover {
  background-image: url("../data/images/ui/system-icon-back-neg.svg");
}
.header-navigation .tools-button .notification {
  top: -6px;
  right: -7px;
}
.header-navigation .tools-login a {
  min-width: 60px;
  position: relative;
}
.header-navigation .tools-login a span:last-child {
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
}
.header-navigation .tools-login a:hover span:first-child {
  visibility: hidden;
}
.header-navigation .tools-login a:hover span:last-child {
  display: initial;
}

/*
	Tour stage
*/
.tour-stage {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #111111;
  color: #ffffff;
}

.section-back-button {
  position: absolute;
  top: 13px;
  left: 30px;
}
.view-close-button {
  display: block;
  position: absolute;
  z-index: 1;
  top: 13px;
  left: 30px;
  width: 20px;
  height: 20px;
  background: 50%/contain no-repeat;
  background-image: url("../data/images/ui/system-icon-close-dark.svg");
}

.tour-message-button {
  display: block;
  position: absolute;
  z-index: 1;
  bottom: 13px;
  left: 30px;
  background: rgba(0, 53, 142, 0.4);
  bottom: 55px;
  background: rgba(0, 53, 142, 0.8);
}
.tour-message-button:hover {
  background: #43b02a;
}

/*
	Mouse spot
*/
.mouse-spot {
  display: none;
  position: absolute;
  z-index: 32;
}
.mouse-spot:after {
  content: " ";
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 128, 255, 0.5);
}

.tour-section-controller {
  position: absolute;
  width: 100%;
  height: calc(100% - 64px);
}

.tour-section .tour-perspective-container, .tour-section .tour-background-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform-style: flat;
  perspective: 150px;
  perspective-origin: 50% 67%;
}
.tour-section .tour-perspective-container > div, .tour-section .tour-background-image-container > div {
  position: absolute;
  left: 50% !important;
  top: 50% !important;
}

.tour-section {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.tour-section .tour-section-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #f4f4f4;
}
.tour-section .tour-section-content > :first-child {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.tour-section .tour-background-image-container {
  background: #a0a0a0;
}
.tour-section .tour-background-image-container img {
  transform: translate(-50%, -50%);
}
.tour-section .tour-hot-spots {
  position: absolute;
  left: 50%;
  top: 50%;
}
.tour-section .tour-hot-spot {
  position: absolute;
  z-index: 1;
}
.tour-section .tour-hot-spot > * {
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
}
.tour-section .tour-hot-spot--label__hidden {
  display: none;
}
.tour-section .tour-hot-spot:hover .tour-hot-spot--label {
  background: #43b02a;
}
.tour-section .tour-hot-spot:hover .tour-hot-spot--label__hidden {
  display: block;
}
.tour-section .tour-hot-spot--area {
  /*
  background: rgba(green, .3);
  border: 2px dashed yellow;
  */
  width: 200px;
  height: 100px;
}

/*
	Chat
*/
.chat-controller {
  position: absolute;
  background: #f6f6f6;
  width: 200px;
  height: 300px;
  border-left: 4px solid #111111;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chat-controller > *.chat-header {
  flex: 0 1 auto;
  padding: 13px 77px 10px;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -5px;
  text-align: center;
}
.chat-controller > *.chat-header > * {
  margin-left: auto;
  margin-right: auto;
}
.chat-controller > *.chat-i-frame {
  flex: 1 0 auto;
  width: 100%;
}
.chat-controller .chat-controller-icon {
  width: 120px;
  height: 120px;
  background: url("../data/images/ui/system-icon-chat-grey.svg") 50%/contain no-repeat;
  margin-bottom: -17px;
  margin-top: -5px;
}
.chat-controller h2 {
  font-size: 14px;
  line-height: 20px;
  color: #a8a8a8;
  margin-right: -0.75em;
  margin-bottom: -9px;
  margin-top: calc(1.5rem - 4px);
}
.chat-controller h2:empty {
  margin-top: 7px;
  height: 0px;
}
.chat-controller .view-close-button {
  left: 13px;
  background-image: url("../data/images/ui/system-icon-close-light.svg");
}

.chat-i-frame {
  border: none;
}

/*
	Chat content
*/
.chat-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  height: 100vh;
  padding: 13px;
  background: #f6f6f6;
}
.chat-content > div {
  flex: 1 0 auto;
  background: #f6f6f6 url("../data/images/ui/chat-content-background.svg") 50%/15px;
}

/*
	Tour split view
*/
.tour-split-view {
  position: absolute;
  background: #282828;
  width: 200px;
  height: 300px;
  border-top: 4px solid #111111;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tour-split-view .tour-split-view-icon {
  width: 120px;
  height: 115px;
  background: url("../data/images/ui/content-icon-waving-hand.svg") 50%/contain no-repeat;
  margin-left: -7px;
  margin-top: -3px;
}
@media (max-width: 1279px) {
  .tour-split-view .action-button {
    display: block;
  }
}
.tour-split-view .action-button--restart {
  border-color: #43b02a !important;
}
.tour-split-view > div {
  width: calc(100% - 200px);
  max-width: 840px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding-top: 7px;
  padding-bottom: 13px;
}
@media (max-width: 1023px) {
  .tour-split-view > div {
    width: calc(100% - 160px);
  }
}
.tour-split-view > div > *:first-child {
  flex: 0 1 auto;
}
.tour-split-view > div > *:last-child {
  margin-top: 13px;
  flex: 1 0 auto;
  display: flex;
  align-items: stretch;
}
.tour-split-view > div > *:last-child > *:first-child {
  flex: 1 0 auto;
  margin-right: 20px;
}
@media (min-width: 1024px) {
  .tour-split-view > div > *:last-child > *:first-child {
    min-width: 480px;
  }
}
.tour-split-view > div > *:last-child > *:last-child {
  flex: 0 1 auto;
  align-self: flex-start;
}
.tour-split-view > div > *:last-child .action-button.disabled {
  opacity: 0.2;
}
@media (min-width: 1024px) {
  .tour-split-view > div > *:last-child.sent > *:last-child {
    align-self: flex-end;
  }
}
.tour-split-view > div > *:last-child.sent > *:last-child .action-button {
  margin-top: 20px;
}
.tour-split-view > div > *:last-child.sent textarea {
  border-color: #707070;
}
@media (max-width: 1023px) {
  .tour-split-view > div > *:last-child.sent .optional {
    display: none;
  }
}
.chat-disabled .tour-split-view > div > *:last-child.sent .optional, body.IE .tour-split-view > div > *:last-child.sent .optional {
  display: none;
}
@media (max-width: 1023px) {
  .tour-split-view > div > *:last-child {
    flex-direction: column;
  }
  .tour-split-view > div > *:last-child > *:first-child {
    margin-right: 0;
    margin-bottom: 13px;
  }
}
.tour-split-view h2, .tour-split-view p {
  color: #989898;
  margin-left: 15px;
}
@media (max-width: 1279px) {
  .tour-split-view h2, .tour-split-view p {
    margin-left: 8px;
  }
}
@media (min-width: 1280px) {
  .tour-split-view p {
    margin-left: 0;
  }
}
.tour-split-view p a {
  color: inherit;
}
.tour-split-view h2:before {
  background-image: url("../data/images/ui/content-icon-waving-hand.svg");
  margin-left: -3px;
  margin-right: 6px;
  margin-bottom: -12px;
}
.tour-split-view .success-message {
  margin-bottom: -4px;
}
.tour-split-view .success-message em {
  font-style: normal;
  color: #43b02a;
}
.tour-split-view .success-message.error-message {
  margin-top: 15px;
  color: #e42444;
}
.tour-split-view form textarea {
  display: block;
  resize: none;
  background: #282828;
  border: 1px solid #c3c3c3;
  border-radius: 0.25rem;
  font-family: inherit;
  color: #ffffff;
}
.tour-split-view form textarea::placeholder {
  color: #707070;
}
.tour-split-view form textarea:focus {
  outline: none;
  border-color: #43b02a;
}
.tour-split-view form .action-button {
  background: transparent;
  border: 1px solid #c3c3c3;
}
.tour-split-view form .action-button .label-icon, .tour-split-view form .action-button .overlay ul.download-list a:before, .overlay ul.download-list .tour-split-view form .action-button a:before {
  top: -4px;
  margin-right: 5px;
}
.tour-split-view form .action-button:hover {
  background: #43b02a;
  border-color: #43b02a;
  /*
  border-color: $union-green;
  color: $union-green;

  .label-icon {
  	background-image: url("../data/images/ui/system-icon-send-neg-green.svg");

  }
  */
}
.tour-split-view .view-close-button {
  background-image: url("../data/images/ui/system-icon-close-dark.svg");
}
.tour-split-view.credit-split-view, .tour-split-view.expert-split-view {
  background: #f6f6f6;
}
.tour-split-view.credit-split-view h2, .tour-split-view.expert-split-view h2 {
  color: #00358e;
  font-weight: bold;
}
.tour-split-view.credit-split-view form textarea, .tour-split-view.expert-split-view form textarea {
  background: transparent;
  color: #404040;
}
.tour-split-view.credit-split-view form .action-button, .tour-split-view.expert-split-view form .action-button {
  background: #00358e;
  border-color: #00358e;
}
.tour-split-view.credit-split-view form .action-button:hover, .tour-split-view.expert-split-view form .action-button:hover {
  background: #43b02a;
  border-color: #43b02a;
}
.tour-split-view.credit-split-view form .action-button.disabled, .tour-split-view.expert-split-view form .action-button.disabled {
  opacity: 0.55;
}
.tour-split-view.credit-split-view .view-close-button, .tour-split-view.expert-split-view .view-close-button {
  background-image: url("../data/images/ui/system-icon-close-light.svg");
}

/*
	Video player
*/
.tour-video-player {
  position: absolute;
  z-index: 1;
  /*
  left: 50.6% !important;
  top: 46.7% !important;

  width: 43.2%;
  height: 43.6%;
  */
  /*
  width: 100%;
  height: 100%;

  .sprite {
  	width: 100%;
  	height: 100%;

  }
  */
  /*
  &:hover {
  	.streaming-player-container {
  		&:before {
  			content: "";
  			display: block;

  			position: absolute;
  			z-index: 1;

  			left: -50%;
  			top: -50%;

  			width: 100%;
  			height: 48px;

  			background: rgba(0, 0, 0, .8);

  		}

  	}

  }
  */
}
.tour-video-player .video-player-zoom-button {
  position: absolute;
  z-index: 1;
  left: calc(-50% + 13px);
  top: calc(-50% + 13px);
  width: 24px;
  height: 24px;
  background: 50%/contain no-repeat;
  background-image: url("../data/images/ui/system-icon-expand-neg.svg");
  opacity: 0.85;
}
.tour-video-player .video-player-zoom-button:hover {
  background-image: url("../data/images/ui/system-icon-expand-neg-green.svg");
  opacity: 1;
}
.tour-video-player.flipped .video-player-zoom-button {
  left: calc(50% - 0px);
  background-image: url("../data/images/ui/system-icon-expand-flipped-neg.svg");
}
.tour-video-player.flipped .video-player-zoom-button:hover {
  background-image: url("../data/images/ui/system-icon-expand-flipped-neg-green.svg");
}
.tour-video-player .video-player-language-button {
  pointer-events: initial;
  position: absolute;
  z-index: 2;
  left: calc(-50% + 65px + 13px);
  top: calc(-50% + 2px);
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #c0c0c0;
  display: none !important;
}
.tour-video-player .video-player-language-button:hover {
  color: #43b02a;
}
.tour-video-player.fullscreen .video-player-language-button, .tour-video-player:hover .video-player-language-button {
  display: block;
}
.tour-video-player.zoomed .streaming-player-container:before {
  content: none;
}
.tour-video-player video.sprite {
  transform: translate(-50%, -50%);
}
.tour-video-player .streaming-player-container {
  pointer-events: none;
  -webkit-touch-callout: none;
}
.tour-video-player .streaming-player-container:after {
  content: " ";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #282828;
  transform: translate(-50%, -50%);
}
.tour-video-player .streaming-player-container .video-player-zoom-button,
.tour-video-player .streaming-player-container .js3q-wrapper,
.tour-video-player .streaming-player-container iframe {
  pointer-events: initial;
  -webkit-touch-callout: initial;
}
body.SAFARI .tour-video-player .streaming-player-container video::-webkit-media-text-track-container {
  position: relative;
}
body.SAFARI .tour-video-player .streaming-player-container video::-webkit-media-text-track-display {
  position: absolute;
  top: auto !important;
  bottom: calc(100% - 18px);
  width: 100%;
  font-family: "FrutigerNeue", sans-serif;
  font-size: 16px !important;
  line-height: 20px !important;
}
@media (max-width: 1023px) {
  body.SAFARI .tour-video-player .streaming-player-container video::-webkit-media-text-track-display {
    bottom: calc(100% - 14px);
    font-size: 14px !important;
    line-height: 18px !important;
  }
}
body.SAFARI .tour-video-player .streaming-player-container video::cue {
  background: rgba(0, 53, 142, 0.8);
}
.tour-video-player .js3q-wrapper {
  transform: translate(-50%, -50%);
}
.tour-video-player .video-player-i-frame {
  border: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.video-player-i-frame:fullscreen {
  transform: none;
}

/*
.streaming-player .view-close-button,
[id=auditorium] .view-close-button {
	left: auto !important;
	right: 13px;

}
*/
.tour-video-player video.sprite:fullscreen {
  transform: none !important;
}

.view-close-button.flipped {
  left: auto !important;
  right: 13px;
}

/*
	Overlays
*/
.overlay-controller {
  z-index: 2;
  background: rgba(246, 246, 246, 0.95);
  color: #404040;
}
.overlay-controller .view-close-button {
  background-image: url("../data/images/ui/system-icon-close-light.svg");
}
body.IE .overlay-controller {
  overflow: auto;
  padding-left: 30px;
  padding-right: 30px;
}
.overlay-controller > div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}
body.IE .overlay-controller > div {
  position: static;
}
.overlay-controller > div > .text-container {
  min-height: 100%;
}
.overlay-controller > div > .text-container > div {
  flex: 0 1 auto;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  margin-left: auto;
  margin-right: auto;
  padding: 65px 75px;
}
@media (max-width: 1023px) {
  .overlay-controller > div > .text-container > div {
    padding: 50px;
  }
}
@media (max-width: 767px) {
  .overlay-controller > div > .text-container > div {
    padding: 35px;
  }
}

body.IE .overlay {
  display: table;
}
.overlay h2, .overlay h3.h1, .overlay h4, .overlay .lead {
  color: #00358e;
}
.overlay h1:first-child {
  margin-top: -0.2em;
}
.overlay .lead + .h1 {
  margin-top: 5px;
}
.overlay .h1 + .lead {
  margin-top: 45px;
}
.overlay h3 .subline {
  display: block;
  margin-top: 4px;
}
.overlay .dark {
  color: #404040;
}
.overlay .meta-headline {
  color: #00358e;
  margin-bottom: 10px;
}
.overlay .timetable {
  margin-top: 45px;
}
.overlay .timetable td {
  vertical-align: top;
}
.overlay .timetable td:first-child {
  width: 120px;
}
.overlay .timetable tr:not(:first-child) td {
  padding-top: 35px;
}
.overlay .timetable h4 .green {
  display: block;
  color: #43b02a;
  font-weight: 300;
}
.overlay .timetable--green-times td:first-child {
  color: #43b02a;
}
.overlay ul.download-list {
  list-style: none;
}
.overlay ul.download-list li {
  margin-top: 17px;
  text-indent: -36px;
  padding-left: 36px;
}
@media (max-width: 1023px) {
  .overlay ul.download-list li {
    text-indent: -35px;
    padding-left: 35px;
  }
}
.overlay ul.download-list li + li {
  margin-top: 15px;
}
.overlay ul.download-list a {
  text-decoration: none;
  color: #00358e;
}
.overlay ul.download-list a > span {
  text-decoration: underline;
}
.overlay ul.download-list a:hover {
  color: #43b02a;
}
.overlay ul.download-list a:before {
  content: " ";
  display: inline-block;
  top: -1px !important;
  margin-right: 6px !important;
  background-image: url("../data/images/ui/system-icon-document-pdf-pos.svg") !important;
}
.overlay ul.download-list a:hover:before {
  background-image: url("../data/images/ui/system-icon-document-pdf-pos-green.svg") !important;
}
.overlay.downloads-overlay .text-container {
  max-width: 600px;
}
.overlay.downloads-overlay h2, .overlay.downloads-overlay .lead.strong, .overlay.downloads-overlay .action-button {
  margin-top: 38px;
}
.overlay.downloads-overlay .label-icon, .overlay.downloads-overlay ul.download-list a:before {
  margin-right: 9px;
}
.overlay.help-overlay .text-container {
  max-width: none;
}
.overlay.help-overlay h2.small {
  font-size: 28px;
  line-height: 36px;
}
@media (max-width: 1023px) {
  .overlay.help-overlay h2.small {
    font-size: 26px;
    line-height: 34px;
  }
}
.overlay.help-overlay h2 a {
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
}
.overlay.help-overlay h2 a:hover {
  text-decoration: underline;
}
.overlay.help-overlay h2 a.phone-link:before {
  background-image: url("../data/images/ui/system-icon-phone-green-bold.svg");
}
.overlay.help-overlay h2 a.mail-link:before {
  top: 2px;
  background-image: url("../data/images/ui/system-icon-mail-green-bold.svg");
}
.overlay .text-container.i-frame-container {
  position: absolute;
  max-width: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
}
body.IE .overlay .text-container.i-frame-container {
  display: table;
  height: calc(100% - 120px);
}
.overlay .text-container.i-frame-container > div {
  position: relative;
  flex: 1 0 auto;
  padding: 0;
  margin: 60px auto;
  width: calc(100% - 160px);
  height: 100%;
  max-width: 800px;
}
@media (max-width: 1023px) {
  .overlay .text-container.i-frame-container > div {
    width: calc(100% - 60px);
  }
}
.overlay .text-container.i-frame-container > div iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
}
/*
	Log-in overlay
*/
.overlay-controller.log-in {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}
.overlay-controller.log-in form h2 {
  font-weight: bold;
  margin-top: 1.75rem;
}
.overlay-controller.log-in form h2:not(:first-child) {
  margin-top: 1.25rem;
}
.overlay-controller.log-in form h2.error {
  color: #e42444;
}
.overlay-controller.log-in form input {
  font-family: inherit;
}
.overlay-controller.log-in form input[type=text], .overlay-controller.log-in form input[type=email], .overlay-controller.log-in form input[type=password] {
  display: block;
  border: 1px solid #00358e;
  border-radius: 0.25rem;
  margin-top: 13px;
  width: 100%;
}
.overlay-controller.log-in form input[type=text]:focus, .overlay-controller.log-in form input[type=email]:focus, .overlay-controller.log-in form input[type=password]:focus {
  outline: none;
  border-color: #43b02a;
}
.overlay-controller.log-in form input[type=text].error, .overlay-controller.log-in form input[type=email].error, .overlay-controller.log-in form input[type=password].error {
  border-color: #e42444;
}
.overlay-controller.log-in form input[type=checkbox] {
  appearance: none;
  position: absolute;
  width: 30px;
  height: 30px;
  border: 1px solid #00358e;
  border-radius: 0.25rem;
  transform: translateY(6px);
}
.overlay-controller.log-in form input[type=checkbox]:checked {
  background: url("../data/images/ui/system-icon-checkmark.svg") 50%/20px no-repeat;
}
.overlay-controller.log-in form input[type=checkbox]:active {
  background: transparent;
  border-color: #43b02a;
}
.overlay-controller.log-in form input[type=checkbox] + label {
  display: block;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 50px;
  color: #00358e;
}
.overlay-controller.log-in form input[type=checkbox], .overlay-controller.log-in form input[type=checkbox] + label {
  cursor: pointer;
}
.overlay-controller.log-in form [type=submit] {
  display: block;
  margin-left: auto;
  min-width: 130px;
  border: none;
  margin-top: 2.5rem;
  cursor: pointer;
}
.overlay-controller.log-in form .error-message {
  margin-top: 2.5rem;
  margin-bottom: 0.75rem;
  max-width: 360px;
  color: #00358e;
}
.overlay-controller.log-in form .error-message strong {
  font-weight: 600;
  color: #e42444;
  display: block;
  margin-bottom: 9px;
}
.overlay-controller.log-in form .error-message a {
  color: #43b02a;
  font-weight: 600;
  text-decoration: none;
}
.overlay-controller.log-in.pre-launch {
  background: rgba(255, 255, 255, 0.925);
}
body.IE .overlay-controller.log-in.pre-launch > div {
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
}
.overlay-controller.log-in.pre-launch .text-container {
  max-width: 660px;
}
.overlay-controller.log-in.pre-launch .markenform {
  background: transparent;
  box-shadow: none;
  /*
  * {
  	color: #ffffff;

  }
  */
  padding-top: 12.5%;
  padding-bottom: 10.75%;
}
.overlay-controller.log-in.pre-launch .markenform:before {
  background: rgba(255, 255, 255, 0.275);
}