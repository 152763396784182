/*
	Icons
*/

.label-icon {
	display: inline-block;
	
	width: 25px;
	height: 25px;
	
	margin-right: 4px;
	
	background: 50%/contain no-repeat;
	
	margin-bottom: -9px;
	
	position: relative;
	top: -3px;
	
	
	&--person {
		background-image: url("#{$img-root}/ui/system-icon-person-neg.svg");
		
	}
	
	&--form {
		background-image: url("#{$img-root}/ui/system-icon-form-neg.svg");
		
	}
	
	&--form-checked {
		background-image: url("#{$img-root}/ui/system-icon-form-checked-neg.svg");
		
	}
	
	&--chat {
		top: -2px;
		
		background-image: url("#{$img-root}/ui/system-icon-chat-neg-light.svg");
		
	}
	
	&--play {
		background-image: url("#{$img-root}/ui/system-icon-play-neg.svg");
		
	}
	
	&--phone {
		margin-right: 3px;
		
		background-image: url("#{$img-root}/ui/system-icon-phone-neg.svg");
		
	}
	
	&--mail {
		background-image: url("#{$img-root}/ui/system-icon-mail-neg.svg");
		
		width: 24px;
		
	}
	
	&--calendar {
		background-image: url("#{$img-root}/ui/system-icon-calendar-neg-light.svg");
		
		width: 24px;
		
	}
	
	&--waving-hand {
		background-image: url("#{$img-root}/ui/system-icon-waving-hand-neg.svg");
		
	}
	
	&--home {
		background-image: url("#{$img-root}/ui/system-icon-home-neg.svg");
		
	}
	
	&--back {
		top: -4px;
		
		margin-left: -1px;
		margin-right: 5px;
		
		background-image: url("#{$img-root}/ui/system-icon-back-neg-light.svg");
		
	}
	
	&--download {
		background-image: url("#{$img-root}/ui/system-icon-files-neg-light.svg");
		
	}
	
	&--web-page {
		background-image: url("#{$img-root}/ui/system-icon-web-page-neg.svg");
		
	}
	
	&--send {
		background-image: url("#{$img-root}/ui/system-icon-send-neg.svg");
		
	}
	
	&--reload {
		background-image: url("#{$img-root}/ui/system-icon-reload-neg.svg");
		
	}
	
	&--log-in {
		background-image: url("#{$img-root}/ui/system-icon-log-in-neg.svg");
		
	}
	
	&--credits {
		background-image: url("#{$img-root}/ui/system-icon-credit-points-neg.svg");
		
	}
	
}

%prepended-headline-icon {
	&:before {
		content: " ";
		display: inline-block;
		
		position: relative;
		left: -1px;
		
		width: 40px;
		height: 40px;
		
		margin-right: 11px;
		margin-bottom: -8px;
		
		background: 50%/contain no-repeat;
		
		top: 1px;
		
		@media (max-width: $screen-m) {
			width: 36px;
			height: 36px;
			
			margin-right: 10px;
			top: 1px;
			
		}
		
	}
	
}

%notification {
	position: absolute;
	top: -5px;
	right: -8px;
	
	display: block;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	
	background: $union-signal-red url("#{$img-root}/ui/chat-notification-asterisk.svg") 50%/10px no-repeat;
	
}
