/*
	Header navigation
*/

.header-navigation {
	@extend .unselectable;
	
	position: relative;
	z-index: 1024;
	
	left: 0;
	top: 0;
	width: 100%;
	height: 64px;
	
	padding: 13px 30px;
	
	background: $union-blue;
	box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
	
	.action-button {
		border: 1px solid $union-green;
		
		padding: 8px 10px;
		
		font-size: 13px;
		line-height: 20px;
		
		&:hover {
			background: $union-green;
			
		}
		
		
	}
	
	display: flex;
	justify-content: space-between;
	
	> * {
		flex: 0 1 auto;
		
	}
	
	.header-title {
		display: flex;
		align-items: center;
		
		> * {
			flex: 0 1 auto;
			
		}
		
		margin-right: 30px;
		
		.header-logo {
			height: 28px;
			
			margin-right: 29px; // 16px;
			
		}
		
		span {
			@extend .shape;
			
			position: relative;
			top: 1px;
			
			@media (max-width: $screen-m) {
				top: 2px;
				
			}
			
			white-space: nowrap;
			
			@extend %copy-text-large;
			
			strong {
				font-weight: bold;
				color: $union-green;
				
			}
			
		}
		
		.tools-button {
			display: none;
			
			margin-left: 0;
			margin-right: 29px;
			
		}
		
	}
	
	.tools-navigation {
		display: flex;
		align-items: center;
		
		> * {
			flex: 0 1 auto;
			
			+ * {
				margin-left: 30px;
				
			}
			
		}
		
	}
	
	.tools-button {
		font-size: 0;
		line-height: 0;
		
		width: 25px;
		height: 25px;
		
		background: 50%/contain no-repeat;
		
		position: relative;
		
		&--auditorium {
			top: 0px;
			margin-left: 22px;
			margin-right: -8px;
			
			background-image: url("#{$img-root}/ui/system-icon-play-green.svg");
			
			&:hover, &.active {
				background-image: url("#{$img-root}/ui/system-icon-play-green-neg.svg");
				
			}
			
		}
		
		&--chat {
			top: 2px;
			
			background-image: url("#{$img-root}/ui/system-icon-chat-green-neg.svg");
			
			&:hover, &.active {
				background-image: url("#{$img-root}/ui/system-icon-chat-neg.svg");
				
			}
			
		}
		
		&--poll {
			top: 0px;
			margin-left: 27px;
			
			background-image: url("#{$img-root}/ui/system-icon-poll-green-neg.svg");
			
			&:hover, &.active {
				background-image: url("#{$img-root}/ui/system-icon-poll-neg.svg");
				
			}
			
		}
		
		&--credit-points {
			top: 0px;
			
			background-image: url("#{$img-root}/ui/system-icon-credit-points-green-neg.svg");
			
			&:hover, &.active {
				background-image: url("#{$img-root}/ui/system-icon-credit-points-green-neg-light.svg");
				
			}
			
			margin-left: 28px;
			
		}
		
		&--document {
			top: 1px;
			margin-left: 25px;
			
			background-image: url("#{$img-root}/ui/system-icon-document-green-neg.svg");
			
			&:hover, &.active {
				background-image: url("#{$img-root}/ui/system-icon-document-neg.svg");
				
			}
			
		}
		
		&--files {
			top: 1px;
			margin-left: 25px;
			margin-right: 2px;
			
			background-image: url("#{$img-root}/ui/system-icon-files-green-neg.svg");
			
			&:hover, &.active {
				background-image: url("#{$img-root}/ui/system-icon-files-neg.svg");
				
			}
			
		}
		
		&--calendar {
			top: 1px;
			margin-left: 26px;
			
			background-image: url("#{$img-root}/ui/system-icon-calendar-green-neg.svg");
			
			&:hover, &.active {
				background-image: url("#{$img-root}/ui/system-icon-calendar-neg.svg");
				
			}
			
		}
		
		&--help {
			top: 1px;
			
			background-image: url("#{$img-root}/ui/system-icon-help-green-neg.svg");
			
			&:hover, &.active {
				background-image: url("#{$img-root}/ui/system-icon-help-neg.svg");
				
			}
			
		}
		
		&--privacy {
			top: 1px;
			margin-left: 22px;
			
			background-image: url("#{$img-root}/ui/system-icon-privacy-green-neg.svg");
			
			&:hover, &.active {
				background-image: url("#{$img-root}/ui/system-icon-privacy-neg.svg");
				
			}
			
		}
		
		&--back {
			top: 1px;
			
			background-image: url("#{$img-root}/ui/system-icon-back-green-neg.svg");
			
			&:hover {
				background-image: url("#{$img-root}/ui/system-icon-back-neg.svg");
				
			}
			
		}
		
		.notification {
			@extend %notification;
			top: -6px;
			right: -7px;
			
		}
		
	}
	
	.tools-login {
		a {
			min-width: 60px;
			
			position: relative;
			
			span:last-child {
				display: none;
				position: absolute;
				left: 0;
				width: 100%;
				
			}
			
			&:hover {
				span:first-child {
					visibility: hidden;
					
				}
				
				span:last-child {
					display: initial;
					
				}
				
			}
			
		}
		
	}
	
}
