.tour-section-controller {
	position: absolute;
	
	width: 100%;
	height: calc(100% - 64px);
	
}

%perspective-container {
	position: absolute;
	
	width: 100%;
	height: 100%;
	
	overflow: hidden;
	
	transform-style: flat;
	
	perspective: 150px;
	perspective-origin: 50% 67%;
	
	> div {
		position: absolute;
		
		left: 50% !important;
		top: 50% !important;
		
	}
	
}

.tour-section {
	position: absolute;
	
	width: 100%;
	height: 100%;
	
	overflow: hidden;
	
	.tour-section-content {
		position: absolute;
		
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		
		overflow: hidden;
		
		background: #f4f4f4; // #e4e4e4;
		
		> :first-child {
			position: absolute;
			
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			
		}
		
	}
	
	.tour-background-image-container {
		// @extend .shape;
		
		@extend %perspective-container;
		background: #a0a0a0;
		
		img {
			transform: translate(-50%, -50%);
			
		}
		
	}
	
	.tour-perspective-container {
		@extend %perspective-container;
		
	}
	
	.tour-hot-spots {
		position: absolute;
		left: 50%;
		top: 50%;
		
	}
	
	.tour-hot-spot {
		position: absolute;
		z-index: 1;
		
		> * {
			display: block;
			position: absolute;
			
			transform: translate(-50%, -50%);
			
		}
		
		&--label {
			@extend %action-button;
			@extend %action-button__transparent;
			
			&__hidden {
				display: none;
				
			}
			
		}
		
		&:hover .tour-hot-spot--label {
			background: $union-green;
			
			&__hidden {
				display: block;
				
			}
			
		}
		
		&--area {
			/*
			background: rgba(green, .3);
			border: 2px dashed yellow;
			*/
			
			width: 200px;
			height: 100px;
			
		}
		
	}
	
}
