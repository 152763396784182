/*
	Chat
*/

.chat-controller {
	position: absolute;
	
	background: $tour-light-background-color;
	
	width: 200px;
	height: 300px;
	
	border-left: 4px solid $tour-background-color;
	
	display: flex;
	flex-direction: column;
	
	justify-content: center;
	align-items: center;
	
	// padding: 13px;
	
	> * {
		&.chat-header {
			flex: 0 1 auto;
			
			// width: 100%;
			padding: 13px 77px 10px;
			
			margin-left: -20px;
			margin-right: -20px;
			
			margin-top: -5px;
			
			text-align: center;
			
			> * {
				margin-left: auto;
				margin-right: auto;
				
			}
			
		}
		
		&.chat-i-frame {
			flex: 1 0 auto;
			
			width: 100%;
			
		}
		
	}
	
	.chat-controller-icon {
		width: 120px;
		height: 120px;
		
		background: url("#{$img-root}/ui/system-icon-chat-grey.svg") 50%/contain no-repeat;
		
		margin-bottom: -21px + 4px;
		margin-top: -5px;
		
	}
	
	h2 {
		@extend %copy-text;
		
		font-size: 14px;
		line-height: 20px;
		
		color: #a8a8a8;
		
		margin-right: -.75em;
		margin-bottom: -9px;
		
		margin-top: calc(1.5rem - 4px);
		
		&:empty {
			margin-top: 7px;
			height: 0px;
			
		}
		
	}
	
	.view-close-button {
		left: 13px;
		background-image: url("#{$img-root}/ui/system-icon-close-light.svg");
		
	}
	
}

.chat-i-frame {
	border: none;
	
}

/*
	Chat content
*/

.chat-content {
	display: flex;
	flex-direction: column;
	
	align-items: stretch;
	align-content: stretch;
	
	height: 100vh;
	
	padding: 13px;
	background: $tour-light-background-color;
	
	> div {
		flex: 1 0 auto;
		
		background: $tour-light-background-color url("#{$img-root}/ui/chat-content-background.svg") 50%/15px;
		
	}
	
}
