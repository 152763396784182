/*
	Image root
*/

$public-root: "..";
$img-root: "#{$public-root}/data/images"; //use: background-image: url("#{$img-root}/ui/icon-play.svg");

/*
	Breakpoints
*/

$screen-xs: 479px;
$screen-s: 767px;
$screen-m: 1023px;
$screen-l: 1279px;
$screen-xl: 1439px;

$screen-xs-up: $screen-xs + 1px;
$screen-s-up: $screen-s + 1px;
$screen-m-up: $screen-m + 1px;
$screen-l-up: $screen-l + 1px;
$screen-xl-up: $screen-xl + 1px;

/*
	Colours
*/

$union-blue: #00358e;
$union-green: #43b02a;

$union-grey: #c3c3c3;
$union-light-grey: #ecf0f9;
$union-petrol: #008d92;
$union-yellow: #fed210;
$union-light-blue: #1386dd;
$union-violet: #873fd5;
$union-yellow-green: #bad71c;

$union-signal-green: $union-green;
$union-signal-yellow: $union-yellow;
$union-signal-red: #e42444;

$union-text-color: #404040;

$tour-background-color: #111111;
$tour-light-background-color: #f6f6f6;
