/*
	Web fonts
*/

@font-face {
	font-family: "FrutigerNeue";
	src: url("../fonts/unlicensed/FrutigerNeueLTW06-Book.woff2") format("woff2"),
		url("../fonts/unlicensed/FrutigerNeueLTW06-Book.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	
}

@font-face {
	font-family: "FrutigerNeue";
	src: url("../fonts/unlicensed/FrutigerNeueLTW06-Bold.woff2") format("woff2"),
		url("../fonts/unlicensed/FrutigerNeueLTW06-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	
}

@font-face {
	font-family: "UIIconFont";
	src: url("../fonts/UI-IconFont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	
}

%headline {
	font-weight: bold;
	
	font-size: 32px;
	line-height: 44px;
	
	@media (max-width: $screen-m) {
		font-size: 28px;
		line-height: 36px;
		
	}
	
}

%headline-medium {
	font-weight: normal;

	font-size: 20px;
	line-height: 32px;

	@media (max-width: $screen-m) {
		font-size: 18px;
		line-height: 26px;

	}

}

%headline-large {
	font-weight: bold;
	
	font-size: 40px;
	line-height: 48px;
	
	@media (max-width: $screen-m) {
		font-size: 32px;
		line-height: 44px;
		
	}
	
}

%copy-text {
	font-weight: normal;
	
	font-size: 16px;
	line-height: 24px;
	
	@media (max-width: $screen-m) {
		font-size: 14px;
		line-height: 22px;
		
	}
	
}

%copy-text-large {
	font-weight: normal;
	
	font-size: 24px;
	line-height: 32px;
	
	@media (max-width: $screen-m) {
		font-size: 22px;
		line-height: 30px;
		
	}
	
}

%small-print {
	font-size: 13px;
	line-height: 20px;
	
}

body {
	font-family: "FrutigerNeue", sans-serif;
	-webkit-font-smoothing: antialiased;
	
	@extend %copy-text;
	
	color: $union-text-color;
	
	&, * {
		margin: 0;
		padding: 0;
		
	}
	
}

h1, .h1 {
	@extend %headline-large;

	span {
		color:  $union-green;
	}
}

h2 {
	@extend %headline;
	
	* + & {
		margin-top: 1.5rem;
		
	}
	
}

h3 {
	@extend %headline-medium;

	* + & {
		margin-top: 1.5rem;

	}

}

h1, h2, h3 {
	color: $union-blue;
	
}

p:not([class]) {
	margin-top: 16px;
	
	@media (max-width: $screen-s) {
		margin-top: 14px;
		
	}
	
}

p.lead {
	@extend %copy-text-large;
	
	* + & {
		margin-top: .55em;
		
	}
	
}

p a {
	color: $union-blue;
	
	&:hover {
		color: $union-green;
		
	}
	
}

strong {
	font-weight: bold;
	color: $union-green;
	
}

/*
	Interaction
*/

%control-padding {
	padding: 14px 16px;
	
	@media (max-width: $screen-l) {
		padding: 9px 10px 10px;
		
	}
	
}

%bordered-control-padding {
	padding: 13px 15px;
	
	@media (max-width: $screen-l) {
		padding: 8px 9px 9px;
		
	}
	
}

%action-button {
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.27rem;
	
	text-decoration: none;
	text-align: center;
	white-space: nowrap;
	
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
	
	border-radius: 0.25rem;
	
	color: #ffffff;
	background-color: $union-blue;
	
	@extend %control-padding;
	
	&:hover {
		background: $union-green;
		
	}
	
	@media (max-width: $screen-l) {
		font-size: 13px;
		line-height: 20px;
		
		.label-icon {
			margin-bottom: -11px;
			
		}
		
	}
	
}

%action-button__transparent {
	background: rgba($union-blue, .8);
	
	&:hover {
		background: $union-green;
		
	}
	
}

%action-button__compact {
	padding-top: 4px;
	padding-bottom: 5px;
	
}

.action-button {
	@extend %action-button;
	
	border: none;
	
	* + & {
		margin-top: 1.85em;
		
	}
	
}

/*
	Grid
*/

.text-container {
	width: calc(100% - 60px);
	max-width: 800px;
	
	margin-left: auto;
	margin-right: auto;
	
	padding-top: 2.5rem;
	padding-bottom: calc(2.5rem + .5vh);
	
	min-height: 100vh;
	
	display: flex;
	flex-direction: column;
	
	justify-content: center;
	align-items: flex-start;
	
	> * {
		flex: 0 1 auto;
		
	}
	
	body.IE & {
		display: table-cell;
		min-height: 0 !important;
		
		margin-left: auto;
		margin-right: auto;
		vertical-align: middle;
		
		> * {
			display: table;
			margin-left: auto;
			margin-right: auto;
			
		}
		
	}
	
}

.markenform {
	position: relative;
	
	padding-left: 45px;
	padding-right: 45px;
	
	padding-top: calc(25% / 2);
	padding-bottom: calc(15% / 2);
	
	&:before {
		content: " ";
		
		position: absolute;
		z-index: -1;
		
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		
		transform: skewY(-11deg);
		
		border: 2px solid $union-green;
		border-radius: 0 84px;
		
		@media (max-width: $screen-s) {
			border-radius: 0 72px;
			
		}
		
	}
	
}
